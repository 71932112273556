<template>
	<div class="mycustomer">
		<div class="my-title">
			<p>悦融易推荐给我的客户</p>
		</div>
		<div style="padding: 0 10px">
			<div>
				<span style="margin: 0 0 0 10px">客户名称:</span>
				<el-input style="width: 20%; margin: 0 10px" placeholder="请输入客户名称" v-model="params.clientName"></el-input>
				<span style="margin: 0 0 0 10px">处理状态:</span>
				<el-select clearable style="width: 20%; margin: 0 10px" v-model="params.dealStatus" placeholder="请选择">
					<el-option v-for="item in ClientDealStatus" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-button type="primary" @click="() => {
					params.pageNo = 1;
					getClientListOfPresident();
				}
					">查询</el-button>

				<el-button type="primary" @click="() => {
					params.clientName = '';
					params.dealStatus = '';
					params.pageNo = 1;
					getClientListOfPresident();
				}
					">清空</el-button>
			</div>
			<div style="padding: 0 10px">
				<!-- table 列表 -->
				<el-table height="calc(100vh - 290px)" style="margin: 10px 0" :data="tableData" border>
					<el-table-column align="left" label="客户名称" min-width="260">
						<template slot-scope="scope">
							<div style="padding-top: 8px; padding-right: 94px">
								<el-badge v-if="scope.row.dealStatus == 1 ||
									scope.row.dealStatus == 2
									" value="需要我反馈" class="item">
									<div class="blue" @click="
										showDialogVisible(
											scope.row,
											'customerDialogVisible',
											3
										)
										" style="cursor: pointer">
										{{
											scope.row.whetherSensitive == 1
											? scope.row.pdfClientName
											: scope.row.clientName
										}}
									</div>
								</el-badge>
								<div v-else class="blue" @click="
								showDialogVisible(
									scope.row,
									'customerDialogVisible',
									3
								)
									" style="cursor: pointer">
									{{
										scope.row.whetherSensitive == 1
										? scope.row.pdfClientName
										: scope.row.clientName
									}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="clientTypeName" label="客户类型" width="80">
					</el-table-column>
					<el-table-column width="110" align="center" prop="productManagerName" label="悦融易对接人">
					</el-table-column>

					<el-table-column width="120" align="center" prop="mobilePhone" label="联系电话">
					</el-table-column>

					<el-table-column width="100" align="center" label="推荐日期">
						<template slot-scope="scope">
							{{
								scope.row.addTime &&
								scope.row.addTime.split(' ')[0]
							}}
						</template>
					</el-table-column>

					<el-table-column width="150" align="left" prop="" label="处理状态">
						<template slot-scope="scope">
							<div>
								{{ scope.row.dealStatusName }}
							</div>
						</template>
					</el-table-column>

					<el-table-column width="100" align="center" label="处理时间">
						<template slot-scope="scope">
							{{
								scope.row.dealTime &&
								scope.row.dealTime.split(' ')[0]
							}}
						</template>
					</el-table-column>

					<el-table-column min-width="130" align="left" label="悦融易反馈信息">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" placement="right-start">
								<div v-html="fn(scope.row.feedbackInformation || '')
										" slot="content"></div>
								<div style="
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 2;
										overflow: hidden;
										cursor: pointer;
									">
									{{ scope.row.feedbackInformation }}
								</div>
							</el-tooltip>
						</template>
					</el-table-column>

					<el-table-column width="120" align="center" label="悦融易反馈时间">
						<template slot-scope="scope">
							{{
								scope.row.feedbackTime &&
								scope.row.feedbackTime.split(' ')[0]
							}}
						</template>
					</el-table-column>

					<el-table-column width="220" fixed="right" align="center" label="操作">
						<template slot-scope="scope">
							<div style="display: flex; justify-content: flex-end">
								<p v-if="scope.row.dealStatus == 1 ||
										scope.row.dealStatus == 2 ||
										scope.row.dealStatus == 3 ||
										scope.row.dealStatus == 4
										" style="
										color: #02a7f0;
										cursor: pointer;
										margin-right: 17px;
									" @click="showDialogVisible(scope.row)">
									反馈我的合作意愿
								</p>
								<p @click="showReplyVisible(scope.row)" style="color: #02a7f0; cursor: pointer">
									快捷回复
								</p>
							</div>
						</template>
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
				<!-- 分页 -->
				<div style="text-align: end">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="params.pageNo" :page-sizes="[5, 10, 20, 30, 50]" :page-size="10"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>

		<infoDrawer :PresidentCooperateWillingness="PresidentCooperateWillingness"
			:CompliesCreditProductPolicy="CompliesCreditProductPolicy" :InterestedProvidingFinanceSolutions="InterestedProvidingFinanceSolutions
				" :allData="allData" :dialogVisible.sync="dialogVisible" :paramsId="paramsId" @closeVisible="closeVisible"
			:privacyType="privacyType" />

		<publicReply :paramsId="paramsId" :replyVisible.sync="replyVisible" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
	clientListOfPresident,
	getSensitiveClientInfo,
	itemByType,
	getClientInfoSensitive,
	getClientInfo,
} from '@/api/index.js';
import infoDrawer from './module/infoDrawer.vue';
export default {
	name: 'Mycustomer',
	data() {
		return {
			ClientDealStatus: [],
			tableData: [],
			dialogVisible: false,
			replyVisible: false,
			params: {
				pageSize: 10,
				pageNo: 1,
				clientName: '',
				dealStatus: '',
			},
			total: 0,
			paramsId: {
				clientId: '',
				clientPresidentRelatedId: '',
				dealStatus: 0,
				businessType: 8,
				cooperationPresidentId: '',
			},
			allData: {},
			PresidentCooperateWillingness: [],

			CompliesCreditProductPolicy: [],
			InterestedProvidingFinanceSolutions: [],
			privacyType: 1,
		};
	},
	methods: {
		fn(str = '') {
			return str ? str.replace(/\n/g, '<br/>') : '';
		},
		async getClientListOfPresident() {
			const res = await clientListOfPresident(this.params);
			this.tableData = res.list;
			this.total = res.totalRows;
		},
		handleSizeChange(val) {
			this.params.pageSize = val;
			this.getClientListOfPresident();
		},
		handleCurrentChange(val) {
			this.params.pageNo = val;
			this.getClientListOfPresident();
		},
		showReplyVisible(row) {
			this.replyVisible = true;
			this.paramsId.clientPresidentRelatedId =
				row.clientPresidentRelatedId;
		},
		showDialogVisible(row) {
			this.dialogVisible = true;
			this.paramsId.clientId = row.clientId;
			this.paramsId.dealStatus = row.dealStatus;
			this.paramsId.clientPresidentRelatedId =
				row.clientPresidentRelatedId;
			this.getClientInfoSensitive({
				clientPresidentRelatedId: row.clientPresidentRelatedId,
			});
			this.getClientListOfPresident();
		},
		async triggerGetClientInfo(type) {
			let res = {};
			if (type == 1) {
				res = await getSensitiveClientInfo(this.paramsId);
			} else if (type == 2) {
				res = await getClientInfo(this.paramsId);
			}
			if (res.clientBase.businessInformationAttachment) {
				res.clientBase.businessInformationAttachment = JSON.parse(
					res.clientBase.businessInformationAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientBase.capitalizeEnterpriseCreditAttachment) {
				res.clientBase.capitalizeEnterpriseCreditAttachment = JSON.parse(
					res.clientBase.capitalizeEnterpriseCreditAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.personsList.length) {
				res.personsList.forEach((element) => {
					if (element.controlPersonAttachment) {
						element.controlPersonAttachment = JSON.parse(
							element.controlPersonAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
					if (element.personalCreditAttachment) {
						element.personalCreditAttachment = JSON.parse(
							element.personalCreditAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
					if (element.otherAttachment) {
						element.otherAttachment = JSON.parse(
							element.otherAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}

				});
			}
			if (res.clientBase.capitalizeAttachment) {
				res.clientBase.capitalizeAttachment = JSON.parse(
					res.clientBase.capitalizeAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.personalAssetsList.length) {
				res.personalAssetsList.forEach((element) => {
					if (element.assetAttachment) {
						element.assetAttachment = JSON.parse(
							element.assetAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.capitalizesList.length) {
				res.capitalizesList.forEach((element) => {
					if (element.guaranteeAttachment) {
						element.guaranteeAttachment = JSON.parse(
							element.guaranteeAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}

			if (res.clientFinanceVO.balanceSheetAttachment) {
				res.clientFinanceVO.balanceSheetAttachment = JSON.parse(
					res.clientFinanceVO.balanceSheetAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.cashFlowStatementAttachment) {
				res.clientFinanceVO.cashFlowStatementAttachment = JSON.parse(
					res.clientFinanceVO.cashFlowStatementAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.incomeStatementAttachment) {
				res.clientFinanceVO.incomeStatementAttachment = JSON.parse(
					res.clientFinanceVO.incomeStatementAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.taxPaymentCertificateAttachment) {
				res.clientFinanceVO.taxPaymentCertificateAttachment =
					JSON.parse(
						res.clientFinanceVO.taxPaymentCertificateAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
			}
			if (res.clientFinanceVO.taxReturnsAttachment) {
				res.clientFinanceVO.taxReturnsAttachment = JSON.parse(
					res.clientFinanceVO.taxReturnsAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.financeRequirementList.length) {
				res.financeRequirementList.forEach((element) => {
					if (element.reqRelevantAttachment) {
						element.reqRelevantAttachment = JSON.parse(
							element.reqRelevantAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}

			if (res.otherKeyInfo.taxPaymentCertificateAttachment) {
				res.otherKeyInfo.taxPaymentCertificateAttachment = JSON.parse(
					res.otherKeyInfo.taxPaymentCertificateAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.otherKeyInfo.vatReturnAttachment) {
				res.otherKeyInfo.vatReturnAttachment = JSON.parse(
					res.otherKeyInfo.vatReturnAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.otherKeyInfo.incomeTaxReturnAttachment) {
				res.otherKeyInfo.incomeTaxReturnAttachment = JSON.parse(
					res.otherKeyInfo.incomeTaxReturnAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.otherKeyInfo.otherAttachment) {
				res.otherKeyInfo.otherAttachment = JSON.parse(
					res.otherKeyInfo.otherAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.riskScreeningInformationList.length) {
				res.riskScreeningInformationList.forEach((item) => {
					if (!item.tryingToStudy) {
						item.tryingToStudy = '--';
					}
				});
			}
			if (res.checkedTitleField) {
				res.checkedTitleField = res.checkedTitleField.split(',');
				res.ischeckedTitleField = true;
			} else {
				res.ischeckedTitleField = false;
				res.checkedTitleField = [
					'financeRequirement',
					'clientBase',
					'clientBase_businessInfo',
					'clientBase_historyLitigation',
					'clientBase_shareholder',
					'clientBase_historicalCredit',
					'clientBase_cooperationBank',
					'clientKeyInfo',
					'controlPerson',
					'clientPersonalAssets',
					'businessCase',
					'businessCase_businessArea',
					'businessCase_businessDownstream',
					'businessCase_businessUpstream',
					'businessCase_mainSupplier',
					'businessCase_downClient',
					'businessCase_productIntroduction',
					'businessCase_description',
					'businessCase_coreCompetitivenessVista',
					'businessCase_shortBoardProblems',
					'clientFinance',
					'clientFinance_subject',
					'clientFinance_quota',
					'clientFinance_analyse',
					'taxInfo',
					'bankFlow',
					'bankFlow_flowInfo',
					'bankFlow_topTen',
					'clientCapitalize',
					'capitalizeEnterpriseCreditAttachment_attachment',
					'controlPersonAttachment_attachment',
					'personalCreditAttachment_attachment',
					'otherAttachment_attachment'
				];
			}
			this.allData = res;
		},
		async getClientInfoSensitive(data) {
			const res = await getClientInfoSensitive(data);
			this.privacyType = res;
			this.triggerGetClientInfo(res);
		},
		async getItemByType(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			this[name] = res.data.map((item) => ({
				value: item.itemValue,
				label: item.itemName,
			}));
		},
		closeVisible() {
			this.dialogVisible = false;
			this.getClientListOfPresident();
		},
	},
	computed: {
		...mapGetters(['getUserInfo']),
	},
	created() {
		this.getClientListOfPresident();
		this.getItemByType('PresidentCooperateWillingness');

		this.getItemByType('CompliesCreditProductPolicy');
		this.getItemByType('InterestedProvidingFinanceSolutions');
		this.getItemByType('ClientDealStatus');
		this.paramsId.cooperationPresidentId =
			this.getUserInfo.userVo.cooperationPresidentId;
	},
	components: {
		infoDrawer,
	},
	watch: {
		dialogVisible(val) {
			if (!val) {
				this.allData = {};
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.mycustomer {
	width: 100%;
	height: 100%;
}

.box-card {
	width: 100%;
}

.blue {
	color: #02a7f0;
}

.red-color {
	color: rgb(240, 2, 0);
}

// ::v-deep .el-table {
// 	height: calc(100vh - 450px) !important;
// 	overflow: auto;
// }
::v-deep .el-badge__content.is-fixed {
	right: 0px;
}
</style>
